<template>
  <div class="elv-layout-main-menu-user-avatar">
    <div class="elv-layout-main-menu-help" :style="{ 'padding-left': isOpenState ? '4px' : '0px' }">
      <SvgIcon name="version_v3" width="16" height="16" />
      <span v-show="isOpenState" @click="onSwitchVersionV3">{{ t('button.switchToV3') }}</span>
    </div>
    <div class="elv-layout-main-menu-help" :style="{ 'padding-left': isOpenState ? '4px' : '0px' }">
      <SvgIcon name="help" width="16" height="16" />
      <span v-show="isOpenState" @click="onClickDocuments">{{ t('button.helpDocuments') }}</span>
    </div>
    <div class="elv-layout-main-menu-language">
      <el-dropdown
        trigger="click"
        placement="bottom-end"
        :show-arrow="false"
        popper-class="elv-layout-main-menu__dropdown-popper"
        @visible-change="onVisibleChange"
      >
        <div>
          <div class="el-dropdown-language" :style="{ 'padding-left': isOpenState ? '4px' : '0px' }">
            <SvgIcon name="language" width="16" height="16" />
            <div v-show="isOpenState">
              <span class="">{{ language }}</span>
              <el-icon class="elv-layout-main-menu__dropdown-icon arrow" @click="onClickMore()">
                <SvgIcon name="menu-top" width="16" height="16" color="#B3BFCE" />
                <!-- :style="{ transform: languageVisible ? 'rotate(180deg)' : 'rotate(0deg)' }" -->
              </el-icon>
            </div>
          </div>
        </div>
        <template #dropdown>
          <el-dropdown-menu class="elv-layout-main-menu__dropdown elv-language" size="large">
            <el-dropdown-item class="elv-layout-main-menu__dropdown--item" @click="translationEn"
              >English</el-dropdown-item
            >
            <el-dropdown-item class="elv-layout-main-menu__dropdown--item" @click="translationCh"
              >简体中文</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div v-if="isLogin" class="elv-layout-main-menu-user-avatar-main__login">
      <VueBlockies
        style="border-radius: 50%"
        :seed="md5UserParams"
        :color="changeAvatarColor(md5UserParams)[0]"
        :bgcolor="changeAvatarColor(md5UserParams)[1]"
        :size="7"
        :scale="4"
        :alt="user?.name"
        spot-color="#666"
      />
      <el-dropdown
        trigger="click"
        placement="bottom-end"
        :show-arrow="false"
        popper-class="elv-layout-main-menu__dropdown-popper"
      >
        <div>
          <div v-if="isOpenState" class="el-dropdown-username">
            <span class="">{{ user?.name }}</span>
            <el-icon class="elv-layout-main-menu__dropdown-icon" @click="onClickMore()">
              <SvgIcon name="more-dian" width="16" height="16" />
            </el-icon>
          </div>
        </div>
        <template #dropdown>
          <el-dropdown-menu class="elv-layout-main-menu__dropdown" size="large">
            <el-dropdown-item class="elv-layout-main-menu__dropdown--item" @click="logout">
              <span class="elv-layout-main-menu__dropdown--item-icon">
                <SvgIcon name="logout" width="19" height="16" />
              </span>
              <span class="elv-layout-main-menu__dropdown--item-text">{{ t('button.logOut') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div
      v-if="!isLogin"
      class="elv-layout-main-menu-user-avatar-main__logout"
      @click="logIn()"
      @mouseenter="isHover = true"
      @mouseleave="isHover = false"
    >
      <SvgIcon name="user-default" width="20" height="20" :fill="isHover ? '#1343BF' : '#333030'" />
      <span v-if="isOpenState" class="elv-layout-main-menu-user-avatar-main-user__login">{{
        t('button.loginButtonTextInDetail')
      }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import md5 from 'js-md5'
import { useI18n } from 'vue-i18n'
import VueBlockies from 'vue-blockies'
import { useCookies } from 'vue3-cookies'
import { avatarColor } from '@/config/index'
import SvgIcon from '@/components/SvgIcon.vue'
import { useGlobalStore } from '@/stores/modules/global'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'

const router = useRouter()
const { t, locale } = useI18n()
const { cookies } = useCookies()
const globalStore = useGlobalStore()
const menuGlobalStore = useMenuGlobalStore()
// const { isOpenState, firstPageUrl } = storeToRefs(menuGlobalStore)
const { isOpenState } = storeToRefs(menuGlobalStore)
const userGlobalStore = useUserGlobalStore()
const { user, isLogin } = storeToRefs(userGlobalStore)

const md5UserParams = ref()
const isHover = ref(false)
const languageVisible = ref(false)

const language = computed(() => {
  return locale.value === 'en' ? 'English' : '简体中文'
})

const languageArrowRotate = computed(() => {
  return languageVisible.value ? 'rotate(0deg)' : 'rotate(180deg)'
})

const translationCh = () => {
  localStorage.setItem('language', 'zh')
  locale.value = 'zh'
  // window.location.reload()
}

const translationEn = () => {
  localStorage.setItem('language', 'en')
  locale.value = 'en'
  // window.location.reload()
}

const changeAvatarColor = (val: string) => {
  const lastStr = val.substr(val.length - 1, 1)
  return avatarColor[lastStr]
}

const onVisibleChange = (visible: boolean) => {
  languageVisible.value = visible
}

const onClickDocuments = () => {
  window.open('https://docs.elven.com/', '_blank')
}

// 退出登录
const logout = () => {
  userGlobalStore.logout()
  globalStore.$reset()
  router.replace({
    name: 'login'
  })
  window.location.reload()
}
// 登录
const logIn = () => {
  router.push({
    name: 'login'
  })
  //   userGlobalStore.openLogin()
}
// 点击了更多按钮
const onClickMore = () => {
  menuGlobalStore.changeMenuFixed(true)
}

const onSwitchVersionV3 = () => {
  window.open(import.meta.env.VITE_APP_VERSION_URL, '_self')
  cookies.set('elv-app-version', 'v3', '3y', '/', import.meta.env.VITE_APP_COOKIES_DOMAIN, true)
}

watchEffect(() => {
  if (isLogin.value) {
    md5UserParams.value = md5(String(userGlobalStore.user?.userId)) + String(userGlobalStore.user?.userId)
  }
})
</script>
<style scoped lang="scss">
.elv-layout-main-menu-user-avatar {
  box-sizing: border-box;
  width: 100%;
  padding-top: 12px;

  .elv-layout-main-menu-help {
    margin-left: 16px;
    padding-left: 4px;
    height: 28px;
    display: flex;
    align-items: center;
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    cursor: pointer;

    svg {
      margin-right: 8px;
    }

    &:hover {
      color: #1e2024;
    }
  }

  .elv-layout-main-menu-language {
    margin-bottom: 9px;
    margin-top: 6px;
    padding-left: 16px;
    cursor: pointer;

    .el-dropdown {
      width: fit-content;
      border-radius: 2px;
      display: flex;
      justify-items: center;
      align-items: center;
      height: 28px;
      padding: 0;
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #636b75;

      &:hover {
        background: #f9fafb;
        color: #1e2024;
      }

      &:focus-within {
        color: #1e2024;
        background: #edf0f3;
      }
    }
  }

  .el-dropdown-language {
    display: flex;
    justify-items: center;
    align-items: center;

    > div {
      display: flex;
      justify-items: center;
      align-items: center;
    }

    span {
      margin-left: 8px;
      margin-right: 10px;
    }
  }

  .elv-layout-main-menu__dropdown-icon {
    position: relative;

    &.arrow {
      transition: all 0.3s;
      transform: v-bind(languageArrowRotate);
    }
  }

  .elv-layout-main-menu-user-avatar-main__login {
    height: 52px;
    box-sizing: border-box;
    border-top: 1px solid $elv-color-E8ECF0;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;

    > img {
      width: 28px;
      height: 28px;
    }

    .el-dropdown-username {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-left: 8px;

      span {
        font-family: 'Plus Jakarta Sans';
        width: 82px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: $elv-theme-base-text-color;
      }
    }
  }

  .elv-layout-main-menu-user-avatar-main__logout {
    height: 52px;
    box-sizing: border-box;
    border-top: 1px solid $elv-color-E8ECF0;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;

    &:hover {
      .elv-layout-main-menu-user-avatar-main-user__login {
        color: $elv-color-1343BF;
      }
    }

    .elv-layout-main-menu-user-avatar-main-user__default {
      height: 20px;
      width: 20px;
    }

    .elv-layout-main-menu-user-avatar-main-user__login {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 13px;
      line-height: 13px;
      color: $elv-color-0E1420;
      margin-left: 12px;
    }
  }
}

.elv-layout-main-menu__dropdown--item-icon {
  width: 15px;
  text-align: center;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.elv-layout-main-menu__dropdown--item-text {
  width: 120px;
  height: 22px;
}

.elv-record-link {
  color: #636b75;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;

  &:hover {
    color: #1e2024;
  }
}
</style>
<style lang="scss">
.elv-layout-main-menu__dropdown-popper {
  overflow: hidden;

  .elv-layout-main-menu__dropdown {
    padding: 4px 0;

    .el-avatar {
      --el-avatar-bg-color: #edf2ff;
    }
    /* 下拉选项 */
    .el-dropdown-menu__item {
      box-sizing: border-box;
      width: 180px;
      background: #ffffff;
      padding: 5px 17px;
      font-size: 12px;
      color: $elv-color-646670;
      justify-content: flex-start;
      align-items: center;
    }

    .el-dropdown-menu__item:not(.is-disabled):focus {
      background-color: $elv-color-F9FBFE;
      color: $elv-color-0E1420;
    }

    &.elv-language {
      .el-dropdown-menu__item {
        width: 154px;
        color: #394048;
      }
    }
  }
}

.elv-layout-main-menu-user-avatar {
  /* 消除小三角 */
  .el-popper__arrow::before {
    width: 0 !important;
    height: 0 !important;
  }
}
</style>
