<template>
  <div ref="menuContentRef" class="elv-layout-main-menu-container">
    <el-scrollbar :height="menuScrollbarHeight">
      <el-collapse ref="projectMenuRef" v-model="openCollapse">
        <el-collapse-item
          v-for="(item, index) in menuMainDataShow"
          :key="item.projectId"
          class="elv-layout-main-menu-level1"
          :name="item.menuName"
          :disabled="true"
        >
          <!-- 一级导航 -->
          <template #title>
            <div
              class="elv-layout-main-menu-title-content"
              @mouseenter="onMouseEnter(index)"
              @mouseleave="onMouseLeave()"
              @click="onClickMenu"
            >
              <div class="elv-layout-main-menu-title-content__icon">
                <SvgIcon
                  :fill="
                    chooseFatherMenuName === item.menuName ? '#2d62eb' : hoverIndex === index ? fillColor : '#b3bfce'
                  "
                  :name="item.menuIcon"
                  width="16"
                  height="16"
                ></SvgIcon>
              </div>
              <div
                v-show="isOpenState"
                class="elv-layout-main-menu-title-content__title"
                @click="onJumpDashboard(item.entityId)"
              >
                {{ utils.formatLanguageContent(item.menuName) }}
              </div>
              <div
                v-show="isOpenState"
                class="elv-layout-main-menu-title-content-right__icon"
                :class="{
                  'elv-layout-main-menu-title-content-right__icon--active': openCollapse.includes(item.menuName)
                }"
                @click="onCheckFirstLevelMenu(item.menuName)"
              >
                <SvgIcon
                  name="menu-top"
                  :fill="hoverIndex === index ? fillColor : '#646670'"
                  width="14"
                  height="14"
                ></SvgIcon>
              </div>
            </div>
          </template>
          <template v-if="item.plan === 'BASIC' || item.plan === 'FREE'">
            <!-- 二级导航 -->
            <div class="elv-layout-main-menu-nav-box">
              <ul v-show="isOpenState">
                <li v-for="(navItem, i) in item.children" :key="index + '-' + i">
                  <MenuMainItem :nav-item="navItem" :is-choosed="chooseId === navItem.id" @onNavChange="onNavChange" />
                </li>
              </ul>
            </div>
          </template>

          <!-- 二级导航 -->
          <el-collapse v-else v-model="openCollapseSecond">
            <template v-if="item.children.length">
              <el-collapse-item
                v-for="(entityData, entityIndex) in item.children"
                :key="entityData.entityId"
                class="elv-layout-main-menu-children"
                :name="entityData.menuName"
                :disabled="true"
                :data-projectId="item.projectId"
              >
                <template #title>
                  <div
                    class="elv-layout-main-menu-children-title-content"
                    @mouseenter="onMouseEnter(entityIndex)"
                    @mouseleave="onMouseLeave()"
                    @click="onClickMenu"
                  >
                    <div
                      v-show="isOpenState"
                      class="elv-layout-main-menu-children-title-content__title"
                      @click="onJumpDashboard(entityData.entityId)"
                    >
                      {{ utils.formatLanguageContent(entityData.menuName) }}
                    </div>
                    <div
                      v-show="isOpenState"
                      class="elv-layout-main-menu-title-content-right__icon"
                      :class="{
                        'elv-layout-main-menu-title-content-right__icon--active': openCollapseSecond.includes(
                          entityData.menuName
                        )
                      }"
                      @click="onCheckSecondLevelMenu(entityData.menuName)"
                    >
                      <SvgIcon
                        name="menu-top"
                        :fill="hoverIndex === index ? fillColor : '#646670'"
                        width="14"
                        height="14"
                      ></SvgIcon>
                    </div>
                  </div>
                </template>
                <!-- 三级导航 -->
                <div class="elv-layout-main-menu-children-nav-box">
                  <ul v-show="isOpenState">
                    <li v-for="(navItem, i) in entityData.children" :key="entityIndex + '-' + i">
                      <MenuMainItem
                        :nav-item="navItem"
                        :is-choosed="chooseId === navItem.id"
                        @onNavChange="onNavChange"
                      />
                    </li>
                  </ul>
                </div>
              </el-collapse-item>
            </template>
            <div
              v-if="user?.userId === item?.creatorId"
              class="elv-layout-menu-main-nav-box__settings"
              :class="{
                'elv-layout-menu-main-nav-box__settings-choose':
                  chooseId === `${item.menuName}${item?.projectId}Settings`
              }"
              @click="
                onNavChange({
                  jumpUrl: `/project/${item?.projectId}/settings`,
                  id: `${item.menuName}${item?.projectId}Settings`,
                  soon: false
                })
              "
            >
              <div>{{ t('menus.settings') }}</div>
            </div>
          </el-collapse>
        </el-collapse-item>
        <div
          :class="[!menuMainDataShow.length ? 'elv-layout-main-menu-nav-create' : 'elv-layout-main-menu-nav-created']"
          @click="onCreateEntity"
        >
          <template v-if="menuMainDataShow.length">
            <SvgIcon name="create-project" width="16" height="16" />
            {{ t('button.create') }}
          </template>
          <p v-else><span>👉</span>{{ t('report.startingFirstProject') }}</p>
        </div>
      </el-collapse>
    </el-scrollbar>
  </div>
</template>
<script lang="ts" setup>
import utils from '@/lib/utils'
import Sortable from 'sortablejs'
import { useI18n } from 'vue-i18n'
import userApi from '@/api/UserApi'
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import MenuMainItem from './MenuMainItem.vue'
import { map, find, uniq, sortBy, filter, flatten } from 'lodash-es'
import { useGlobalStore } from '@/stores/modules/global'
import { useReportStore } from '@/stores/modules/reports/index'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const reportStore = useReportStore()
const globalStore = useGlobalStore()
const userGlobalStore = useUserGlobalStore()
const menuGlobalStore = useMenuGlobalStore()
const { user } = storeToRefs(userGlobalStore)
const { menuMainDataAll, menuChooseId, isOpenState } = storeToRefs(menuGlobalStore)

const menuContentRef = ref()
const projectMenuRef = ref()
const menuScrollbarHeight = ref()
const openCollapse = ref([''])
const openCollapseSecond = ref([''])
const chooseId = ref('')
const chooseFatherMenuName = ref('')
const fillColor = ref('#646670')
const hoverIndex: any = ref(0)

// eslint-disable-next-line no-unused-vars
const currentEntityPermission = computed(() => {
  return reportStore.entityPermission()
})

const menuMainDataShow: any = computed(() => {
  return menuGlobalStore.menuMainDataAll.menuDataAll
})

// 改变导航
const onNavChange = (val: any) => {
  if (!val.soon && (val.jumpUrl !== route.path || route.name === 'project-settings')) {
    if (val?.profileId === Number(route.params?.profileId)) return
    chooseId.value = val.id
    menuGlobalStore.changeMenuChooseId(val.id)
    router.push(val.jumpUrl)
    if (utils.isMobile()) {
      menuGlobalStore.changeMenuOpen(false)
    }
  }
}

const onClickMenu = () => {
  if (utils.isMobile()) {
    menuGlobalStore.changeMenuOpen(true)
  }
}
// 鼠标移入
const onMouseEnter = (indexVal: number) => {
  fillColor.value = '#2d62eb'
  hoverIndex.value = indexVal
}

// 鼠标移出
const onMouseLeave = () => {
  fillColor.value = '#646670'
  hoverIndex.value = null
}
// 改变页面中nav的高度
const changePageMenuWidth = () => {
  const allHeight = document.documentElement.clientHeight || document.body.clientHeight
  menuScrollbarHeight.value = allHeight - 225
  if (globalStore.showUpGradeInfo) {
    menuScrollbarHeight.value -= 31
  }
}

const onCreateEntity = () => {
  if (route.path.indexOf('landing') === -1) {
    router.push({ name: 'landingPage' })
  }
  //  else {
  //   router.push('/landing/pricing')
  // }
}

const onJumpDashboard = (entityId: number) => {
  if (entityId) {
    router.push({ name: 'reports-dashboard', params: { entityId } })
  }
}

const onCheckFirstLevelMenu = (name: string) => {
  if (openCollapse.value.includes(name)) {
    openCollapse.value = openCollapse.value.filter((item: any) => item !== name)
  } else {
    openCollapse.value.push(name)
  }
}

const onCheckSecondLevelMenu = (name: string) => {
  if (openCollapseSecond.value.includes(name)) {
    openCollapseSecond.value = openCollapseSecond.value.filter((item: any) => item !== name)
  } else {
    openCollapseSecond.value.push(name)
  }
}

const onPushOpenCollapse = (name: string) => {
  openCollapse.value.push(name)
  menuGlobalStore.changeMenuChooseId(`${name}Sources`)
}

emitter.on('onPushOpenCollapse', onPushOpenCollapse)

const initSortable = () => {
  nextTick(() => {
    const firstLevelMenu = menuContentRef.value.querySelector('.el-scrollbar__view .el-collapse')
    const secondaryMenuAll = projectMenuRef.value.$el.querySelectorAll('.el-collapse')
    Sortable.create(firstLevelMenu, {
      animation: 300,
      handle: '.elv-layout-main-menu-level1.el-collapse-item .elv-layout-main-menu-title-content__title',
      draggable: '.elv-layout-main-menu-level1.el-collapse-item',
      onStart: () => {
        console.log(projectMenuRef.value.activeNames)
      },
      onEnd: async ({ newIndex, oldIndex }) => {
        if (oldIndex !== newIndex) {
          try {
            let sortedProject: any = []
            if (userGlobalStore.navigationSort?.length) {
              sortedProject = sortBy(globalStore.projectList, (item) => {
                const sortOrderItem = find(userGlobalStore.navigationSort, { projectId: item.projectId })
                return sortOrderItem ? userGlobalStore.navigationSort.indexOf(sortOrderItem) : 999999
              })
            } else {
              sortedProject = globalStore.projectList
            }
            sortedProject.splice(Number(newIndex), 0, sortedProject.splice(Number(oldIndex), 1)[0])

            const navigationSort = sortedProject.map((item: any) => {
              return {
                projectId: item?.projectId,
                entityIds:
                  item?.entityIds && item?.entityIds?.length
                    ? item?.entityIds
                    : item?.entityList?.map((i: any) => i.entityId) ?? []
              }
            })
            await userApi.userNavigationSort(navigationSort)
            ElMessage.success(t('message.success'))
            userGlobalStore.navigationSort = navigationSort
          } catch (error: any) {
            userGlobalStore.userInit()
            ElMessage.error(error.message)
          }
        }
      }
    })
    secondaryMenuAll.forEach((element: any) => {
      Sortable.create(element, {
        animation: 300,
        handle: '.el-collapse-item.elv-layout-main-menu-children .elv-layout-main-menu-children-title-content__title',
        filter: '.elv-layout-menu-main-nav-box__settings',
        draggable: '.el-collapse-item.elv-layout-main-menu-children',
        onStart: () => {},
        onEnd: async (event: any) => {
          if (event?.oldIndex !== event?.newIndex) {
            try {
              let sortedProject: any = []
              if (userGlobalStore.navigationSort?.length) {
                sortedProject = sortBy(globalStore.projectList, (item) => {
                  const sortOrderItem = find(userGlobalStore.navigationSort, { projectId: item.projectId })
                  return sortOrderItem ? userGlobalStore.navigationSort.indexOf(sortOrderItem) : 999999
                })

                sortedProject.forEach((item: any) => {
                  const sortOrderItem: any = find(userGlobalStore.navigationSort, { projectId: item.projectId })
                  if (sortOrderItem) {
                    item.entityList = sortBy(item.entityList, (entity) => {
                      const sortOrderEntity = sortOrderItem.entityIds.indexOf(entity.entityId)
                      return sortOrderEntity !== -1 ? sortOrderEntity : 999999
                    })
                  }
                })
              } else {
                sortedProject = globalStore.projectList
              }
              const projectId = Number(event?.item?.attributes?.['data-projectId']?.value)

              const projectIndex = sortedProject.findIndex((item: any) => item.projectId === projectId)
              const entityList =
                sortedProject[projectIndex]?.entityIds && sortedProject[projectIndex]?.entityIds?.length
                  ? sortedProject[projectIndex]?.entityIds
                  : sortedProject[projectIndex]?.entityList?.map((i: any) => i.entityId) ?? []
              entityList.splice(Number(event.newIndex), 0, entityList.splice(Number(event.oldIndex), 1)[0])
              sortedProject[projectIndex].entityIds = entityList

              const navigationSort = sortedProject.map((item: any) => {
                return {
                  projectId: item?.projectId,
                  entityIds:
                    item?.entityIds && item?.entityIds?.length
                      ? item?.entityIds
                      : item?.entityList?.map((i: any) => i.entityId) ?? []
                }
              })
              await userApi.userNavigationSort(navigationSort)
              ElMessage.success(t('message.success'))
              userGlobalStore.navigationSort = navigationSort
            } catch (error: any) {
              userGlobalStore.userInit()
              ElMessage.error(error.message)
            }
          }
        }
      })
    })
  })
}

watch(
  () => route.name,
  () => {
    const menuMainDataShowFlat = flatten(
      map(menuMainDataShow.value, (item: any) => {
        return item.children
      })
    )
    const entityData = find(menuMainDataShowFlat, { entityId: Number(route.params?.entityId) })
    const routeName = String(route.name)
    let menuId = ''
    if (entityData) {
      if (routeName.indexOf('rules') !== -1) {
        menuId = `${entityData.menuName}${entityData.projectId}Automation`
      } else if (routeName.indexOf('landing') !== -1) {
        menuId = ''
      } else if (routeName.indexOf('contacts') !== -1) {
        menuId = `${entityData.menuName}${entityData.projectId}Contacts`
      } else if (routeName.indexOf('sources') !== -1) {
        menuId = `${entityData.menuName}${entityData.projectId}Sources`
      } else if (routeName.indexOf('transactions') !== -1) {
        menuId = `${entityData.menuName}${entityData.projectId}Transactions`
      } else if (routeName.indexOf('general-ledger') !== -1) {
        menuId = `${entityData.menuName}${entityData.projectId}Ledger`
      } else if (routeName === 'project-settings') {
        menuId = `${entityData.menuName}${entityData.projectId}Settings`
      } else if (routeName === 'entity-settings' || routeName === 'reports-dashboard') {
        menuId = ``
      } else {
        menuId = `${entityData.menuName}${entityData.projectId}Reports`
      }
      menuGlobalStore.changeMenuChooseId(menuId)
      chooseId.value = menuChooseId.value
    }
  }
)
watchEffect(async () => {
  chooseId.value = menuChooseId.value
  // 根据子集，返回父级
  const menuMainDataShowFlat = flatten(
    map(menuMainDataShow.value, (item: any) => {
      return item.children
    })
  )
  const entityData = find(menuMainDataShowFlat, { entityId: Number(route.params?.entityId) })
  const projectData = find(menuMainDataShowFlat, { projectId: Number(route.params?.projectId) })
  if (!menuChooseId.value) {
    const routeName = String(route.name)
    let menuId = ''
    if (entityData) {
      if (routeName.indexOf('rules') !== -1) {
        menuId = `${entityData.menuName}${entityData.projectId}Automation`
      } else if (routeName.indexOf('landing') !== -1) {
        menuId = ''
      } else if (routeName.indexOf('contacts') !== -1) {
        menuId = `${entityData.menuName}${entityData.projectId}Contacts`
      } else if (routeName.indexOf('sources') !== -1) {
        menuId = `${entityData.menuName}${entityData.projectId}Sources`
      } else if (routeName.indexOf('transactions') !== -1) {
        menuId = `${entityData.menuName}${entityData.projectId}Transactions`
      } else if (routeName.indexOf('general-ledger') !== -1) {
        menuId = `${entityData.menuName}${entityData.projectId}Ledger`
      } else if (routeName === 'project-settings') {
        menuId = `${entityData.menuName}${entityData.projectId}Settings`
      } else if (routeName === 'entity-settings' || routeName === 'reports-dashboard') {
        menuId = ``
      } else {
        menuId = `${entityData.menuName}${entityData.projectId}Reports`
      }
    } else if (projectData) {
      if (routeName === 'project-settings') {
        menuId = `${projectData.menuName}${projectData.projectId}Settings`
      } else {
        menuId = ''
      }
    } else if (routeName === 'project-settings') {
      const menuData: any = find(menuMainDataAll.value.menuDataAll, { projectId: Number(route.params?.projectId) })
      menuId = `${menuData?.menuName}${menuData?.projectId}Settings`
    } else {
      menuId = ''
    }
    menuGlobalStore.changeMenuChooseId(menuId)
    chooseId.value = menuChooseId.value
  }
  chooseFatherMenuName.value = filter(menuMainDataShowFlat, (item: any) => {
    return item.id === chooseId.value
  })[0]?.menuName
  menuGlobalStore.changeChooseFatherMenuName(chooseFatherMenuName.value)
})

watchEffect(async () => {
  if (isOpenState) {
    openCollapse.value = uniq([...openCollapse.value])
    openCollapseSecond.value = uniq([...openCollapseSecond.value])
  } else {
    openCollapse.value = []
    openCollapseSecond.value = []
  }
})

onMounted(() => {
  const openMenuList: any = menuMainDataAll.value.menuDataAll.map((item: any) => {
    return item.menuName
  })
  const openSecondMenuList: any = flatten(
    map(
      menuMainDataAll.value.menuDataAll.filter((menu: any) => menu.plan !== 'BASIC' && menu.plan !== 'FREE'),
      (item: any) => {
        return item.children
      }
    )
  ).map((item: any) => {
    return item.menuName
  })
  openCollapseSecond.value = [...openSecondMenuList]
  // eslint-disable-next-line array-callback-return, consistent-return
  openCollapse.value = [chooseFatherMenuName.value, ...openMenuList]
  changePageMenuWidth()
  window.addEventListener('resize', changePageMenuWidth)
  nextTick(() => {
    initSortable()
  })
})

onUnmounted(() => {
  window.removeEventListener('resize', changePageMenuWidth)
  emitter.off('onPushOpenCollapse', onPushOpenCollapse)
})
</script>
<style lang="scss" scoped>
.elv-layout-main-menu-container {
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  .elv-layout-main-menu-title-content {
    height: 32px;
    display: flex;
    box-sizing: border-box;
    align-items: center;

    &:hover {
      .elv-layout-main-menu-title-content__title {
        color: $elv-color-2D62EB;
      }
    }

    .elv-layout-main-menu-title-content__icon {
      margin-left: 16px;
      margin-right: 8px;
      height: 24px;
      display: flex;
      align-items: center;
    }

    .elv-layout-main-menu-title-content__title {
      height: 24px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 24px;
      color: #0e0f11;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 115px;
    }
  }

  .elv-layout-main-menu-children {
    &:first-of-type .elv-layout-main-menu-children-title-content {
      border: 0px;
      margin-top: 0px;
    }

    .elv-layout-main-menu-children-title-content {
      margin-left: 16px;
      margin-right: 10px;
      height: 32px;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      border-top: 1px solid #edf0f3;
      margin-top: 8px;

      &:hover {
        .elv-layout-main-menu-title-title-content__title {
          color: $elv-color-2D62EB;
        }
      }

      .elv-layout-main-menu-title-content__icon {
        margin-left: 16px;
        margin-right: 8px;
        height: 24px;
        display: flex;
        align-items: center;
      }

      .elv-layout-main-menu-title-content-right__icon {
        margin-right: 0px;
      }

      .elv-layout-main-menu-children-title-content__title {
        height: 24px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        color: #aaafb6;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 115px;
        padding-left: 24px;
      }
    }
  }

  .elv-layout-menu-main-nav-box__settings {
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #1e2024;
    cursor: pointer;
    padding: 8px 0;
    margin-left: 16px;
    margin-right: 10px;
    box-sizing: border-box;
    padding-left: 16px;
    border-top: 1px solid #edf0f3;

    > div {
      display: flex;
      align-items: center;
      width: fit-content;
      height: 23px;
      padding: 4px 8px 4px 8px;
      box-sizing: border-box;
    }

    &:hover {
      > div {
        background: $elv-color-E9EFFF;
        border-radius: 20px;
        color: $elv-color-0E1420;
      }
    }

    &.elv-layout-menu-main-nav-box__settings-choose {
      > div {
        background: $elv-color-E9EFFF;
        border-radius: 20px;
        color: $elv-color-0E1420;
      }
    }
  }

  .elv-layout-main-menu-nav-create {
    margin-left: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 154px;
    height: 200px;
    border: 1px dashed #dde1e6;
    border-radius: 3px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #838d95;
    cursor: pointer;

    p {
      display: flex;
      flex-direction: column;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      color: #838d95;

      span {
        font-family: 'Plus Jakarta Sans';
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 4px;
      }
    }

    &:hover {
      border: 1px dashed #5e85eb;
      color: #0e0f11;

      svg {
        fill: #0e0f11;
      }
    }

    svg {
      fill: #838d95;
      margin-right: 4px;
    }
  }

  .elv-layout-main-menu-nav-created {
    padding-left: 16px;
    margin-left: 16px;
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid #edf0f3;
    width: 138px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    height: 22px;
    color: #838d95;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 0px;
    cursor: pointer;

    &:hover {
      color: #0e0f11;

      svg {
        fill: #0e0f11;
      }
    }

    svg {
      fill: #838d95;
      margin-right: 4px;
    }
  }

  .elv-layout-main-menu-nav-box {
    ul {
      margin: 0;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }

  .elv-layout-main-menu-title-content-right__icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .elv-layout-main-menu-title-content-right__icon--active {
    transform: rotate(180deg);
    display: flex;
    align-items: center;
  }
}
</style>

<style lang="scss">
.elv-layout-main-menu-nav-box-main-tip {
  padding: 16px;
}

.elv-layout-main-menu-container {
  .el-collapse {
    border-bottom: 0px;
    border-top: 0px;
  }

  .el-collapse-item {
    &.is-disabled {
      .el-collapse-item__header {
        cursor: pointer;
      }
    }
  }

  .el-collapse-item__header {
    border: none;
    height: auto;
    background: $elv-color-F9FAFB;
  }

  .el-collapse-item__wrap {
    border: none;
    background: $elv-color-F9FAFB;
  }

  .el-collapse-item__content {
    padding-bottom: 0;
  }

  .el-collapse-item__arrow {
    margin: 10px 16px 0 0;
    display: none;
  }

  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
</style>
