<template>
  <div class="elv-layout-main-menu">
    <!-- MenuLogo 的height=64 -->
    <MenuLogo />
    <div id="menumain" class="elv-layout-main-menu-main">
      <MenuMain />
    </div>

    <!-- MenuUserAvater 的height=80 -->
    <MenuUserAvater />
  </div>
</template>
<script lang="ts" setup>
import { concat } from 'lodash-es'
import MenuLogo from './components/MenuLogo.vue'
import MenuMain from './components/MenuMain.vue'
import MenuUserAvater from './components/MenuUserAvater.vue'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'

const route = useRoute()
const menuGlobalStore = useMenuGlobalStore()
const { menuMainDataAll } = storeToRefs(menuGlobalStore)
watchEffect(() => {
  switch (route.name) {
    case 'tableDetail':
      menuGlobalStore.changeMenuChooseId(String(route.params.tableId))
      break
    case 'userScreenerDetail':
      menuGlobalStore.changeMenuChooseId(String(route.params.screenerId))
      break
    case 'marketMaps':
      menuGlobalStore.changeMenuChooseId('marketMaps')
      break
    case 'marketEmergingSpaces':
      menuGlobalStore.changeMenuChooseId('marketEmergingSpaces')
      break
    case 'marketHeatMaps':
      menuGlobalStore.changeMenuChooseId('marketHeatMaps')
      break
    case 'alert':
      // eslint-disable-next-line no-case-declarations
      let alertType = 'rules'
      if (route.params.alertType === 'history') {
        alertType = 'history'
      }
      menuGlobalStore.changeMenuChooseId(alertType)
      break
    default:
      if ((route.params?.tableId && route.params?.profileId) || route.params?.entityId) {
        const reportsData = menuMainDataAll.value.menuDataAll.filter((item: any) => {
          return item.menuName === 'Reports' || item.menuName === 'My Projects' || item.menuName === '我的项目'
        })
        const children = concat(reportsData[0]?.children, reportsData[1]?.children)
        children?.forEach((item: any) => {
          if (
            (route.params?.tableId === item?.tableId && Number(route.params?.profileId) === item?.profileId) ||
            (route.params?.entityId !== undefined && Number(route.params?.entityId) === item?.entityId)
          ) {
            menuGlobalStore.changeMenuChooseId(item.name)
          }
        })
      } else {
        menuGlobalStore.changeMenuChooseId('')
      }
      break
  }
})
</script>
<style lang="scss" scoped>
.elv-layout-main-menu {
  height: 100%;
  box-sizing: border-box;
  background: $elv-color-F9FAFB;
  border-right: 1px solid $elv-color-E8ECF0;

  .elv-layout-main-menu-main {
    box-sizing: border-box;
    // min-height: 300px;
  }
}
</style>
