<template>
  <div class="elv-layout-main-menu-logo">
    <div class="elv-layout-main-menu-logo__img" @click="OnJumpHome()">
      <img
        v-if="isOpenState"
        src="@/assets/img/left-meun-whole-logo.png"
        alt="logo"
        class="elv-project-menu-logo__img--big"
      />
      <img v-else src="@/assets/img/left-menu-logo-small.png" alt="logo" class="elv-project-menu-logo__img--small" />
    </div>

    <div v-if="isOpenState" class="elv-layout-main-menu-logo__icon">
      <div v-if="!isFixedMenu" class="elv-layout-main-menu-logo__icon__tip">{{ t('common.expandControlPanel') }}</div>
      <SvgIcon
        v-if="!isFixedMenu"
        name="menu-open"
        fill="#646670"
        width="16"
        height="16"
        class="elv-layout-main-menu-logo__icon--open"
        @click="onOpenMenu()"
      />

      <SvgIcon
        v-if="!isFixedMenu"
        name="menu-open"
        fill="#2D62EB"
        width="16"
        height="16"
        class="elv-layout-main-menu-logo__icon--open--hover"
        @click="onOpenMenu()"
      />
      <SvgIcon
        v-if="isFixedMenu"
        name="menu-close"
        fill="#646670"
        width="16"
        height="16"
        class="elv-layout-main-menu-logo__icon--close"
        @click="onCloseMenu()"
      />

      <SvgIcon
        v-if="isFixedMenu"
        name="menu-close"
        fill="#2D62EB"
        width="16"
        height="16"
        class="elv-layout-main-menu-logo__icon--close--hover"
        @click="onCloseMenu()"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import { useReportStore } from '@/stores/modules/reports/index'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const reportStore = useReportStore()
const menuGlobalStore = useMenuGlobalStore()
const { isFixedMenu, isOpenState, firstPageUrl } = storeToRefs(menuGlobalStore)

// 关闭（缩小导航）
const onCloseMenu = () => {
  menuGlobalStore.changeMenuFixed(false)
  menuGlobalStore.changeMenuOpen(false)
  if (route.name === 'reports-transactions-for-review' || route.name === 'reports-transactions-categorized') {
    console.log(77777)
    reportStore.agGridApi?.sizeColumnsToFit({ columnLimits: [{ key: 'transactions-from-to', minWidth: 263 }] })
  }
}

const userAgent = computed(() => {
  return window.navigator.userAgent
})

// 打开（放大导航）
const onOpenMenu = () => {
  menuGlobalStore.changeMenuFixed(true)
  if (route.name === 'reports-transactions-for-review' || route.name === 'reports-transactions-categorized') {
    reportStore.agGridApi?.sizeColumnsToFit({ columnLimits: [{ key: 'transactions-from-to', minWidth: 263 }] })
  }
}

const OnJumpHome = () => {
  // router.push('/')
  router.push({
    path: `${firstPageUrl.value}`
  })
}

watch(
  () => userAgent,
  () => {
    if (utils.isMobile()) {
      onCloseMenu()
    }
  },
  { immediate: true }
)
</script>
<style lang="scss" scoped>
.elv-layout-main-menu-logo {
  height: 64px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .elv-layout-main-menu-logo__img {
    height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .elv-project-menu-logo__img--big {
      display: block;
      margin-left: 16px;
      width: 100px;
      height: 32px;
    }

    .elv-project-menu-logo__img--small {
      display: block;
      margin-left: 15px;
      height: 32px;
    }
  }

  .elv-layout-main-menu-logo__icon {
    height: 100%;
    margin-right: 15px;
    box-sizing: border-box;
    padding-top: 30px;
    cursor: pointer;

    &:hover {
      .elv-layout-main-menu-logo__icon--open {
        display: none;
      }

      .elv-layout-main-menu-logo__icon--open--hover {
        display: block;
      }

      .elv-layout-main-menu-logo__icon--close {
        display: none;
      }

      .elv-layout-main-menu-logo__icon--close--hover {
        display: block;
      }

      .elv-layout-main-menu-logo__icon__tip {
        display: block;
      }
    }

    .elv-layout-main-menu-logo__icon--open {
      display: block;
    }

    .elv-layout-main-menu-logo__icon--open--hover {
      display: none;
    }

    .elv-layout-main-menu-logo__icon--close {
      display: block;
    }

    .elv-layout-main-menu-logo__icon--close--hover {
      display: none;
    }

    .elv-layout-main-menu-logo__icon__tip {
      position: fixed;
      display: none;
      top: 4px;
      left: 162px;
      padding: 16px;
      white-space: nowrap;
      font-family: 'Plus Jakarta Sans';
      font-weight: 800;
      font-size: 11px;
      line-height: 140%;
      color: $elv-color-0E1420;
      background: #ffffff;
      border: 1px solid $elv-color-E4E7EB;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
    }
  }
}
</style>
