<template>
  <div class="elv-layout" :class="{ 'elv-layout-fixed': isFixedMenu, 'elv-layout-open': isOpenState && !isFixedMenu }">
    <div v-if="globalStore.showUpGradeInfo" class="elv-layout-upgrade-info">
      <SvgIcon name="upgrade-warning" width="14" height="14" />
      Maintenance upgrade scheduled for September 9th at PM UTC. Estimated duration: 5 hour.
    </div>
    <el-container>
      <div v-if="isOpenState && !isFixedMenu" class="elv-layout-menu-absolute-box"></div>
      <el-aside class="elv-layout-menu" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
        <LeftMenu />
      </el-aside>
      <el-main class="elv-layout-main" :class="{ 'elv-layout-Detail-page': isDetailPage() }">
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
  <!-- 登录弹窗 -->
  <LoginDialog v-if="isShowLoginDialog && !isLogin" />
</template>

<script setup lang="ts">
import LeftMenu from '@/components/LeftMenu/index.vue'
import { useGlobalStore } from '@/stores/modules/global'
import LoginDialog from '@/components/Sign/LoginDialog.vue'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import { useUserGlobalStore } from '@/stores/modules/global/user'

const route = useRoute()
const globalStore = useGlobalStore()
const userGlobalStore = useUserGlobalStore()
const { isShowLoginDialog, isLogin } = storeToRefs(userGlobalStore)

const menuGlobalStore = useMenuGlobalStore()
const { isOpenState, isFixedMenu } = storeToRefs(menuGlobalStore)

const containerHeight = computed(() => {
  return globalStore.showUpGradeInfo ? 'calc(100vh - 31px)' : '100vh'
})

const onMouseEnter = () => {
  if (!isFixedMenu.value) {
    menuGlobalStore.changeMenuOpen(true)
  }
}
const onMouseLeave = () => {
  if (!isFixedMenu.value) {
    menuGlobalStore.changeMenuOpen(false)
  }
}
const isDetailPage = () => {
  const detailPageRoute = [
    'tableDetail',
    'privacyAgreement',
    'userScreenerDetail',
    'balance-sheet',
    'income-statement',
    'cash-flow-statement',
    'general-ledger',
    'transactions',
    'wallets',
    'charts',
    'metrics'
  ]
  if (detailPageRoute.includes(String(route.name))) return true

  return false
}
</script>
<style scoped lang="scss">
.elv-layout {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.elv-layout-upgrade-info {
  width: 100vw;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fcf9e8;
  border-bottom: 1px solid rgba(232, 228, 208, 1);
  color: #746635;
  font-family: 'Plus Jakarta Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  svg {
    margin-right: 6px;
  }
}

.elv-layout-main {
  padding: 0 20px;
  flex: 1;
  overflow: hidden;
}

.elv-layout-menu-absolute-box {
  width: 48px;
  height: 100vh;
}
</style>
<style lang="scss">
.elv-layout {
  .el-aside {
    width: 48px !important;

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
  }

  .el-container {
    height: v-bind('containerHeight');
  }
}

.elv-layout-open {
  position: relative;

  .el-aside {
    position: absolute;
    top: 0;
    width: 180px !important;
    z-index: 9999;
  }
}

.elv-layout-fixed {
  .el-aside {
    overflow: visible;
    width: 180px !important;
  }
}

// ::-webkit-scrollbar {
//   display: none; /* Chrome Safari */
// }

// .el-collapse-item__arrow {
//   display: none;
// }

.elv-layout-Detail-page {
  padding: 0 !important;
}
</style>
