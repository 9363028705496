<template>
  <div class="elv-login-dialog">
    <el-dialog v-model="isShowLoginDialog" title="" width="480px" @close="onCloseDialog()">
      <div class="elv-login-dialog-main">
        <div class="elv-login-dialog__svg">
          <SvgIcon v-if="loginState === 1" name="welcome_dialog" width="320" height="36" />
          <SvgIcon v-if="loginState === 2" name="create_dialog" width="320" height="36" />
        </div>
        <div class="elv-login-dialog__dsc">{{ $t('message.confirmEmailLogin') }}</div>
        <!-- form 表单 -->
        <el-form
          ref="loginFormRef"
          :label-position="'top'"
          label-width="100px"
          style="max-width: 320px"
          :model="loginForm"
          :rules="rules"
          :hide-required-asterisk="false"
        >
          <!-- 邮箱 -->
          <el-form-item v-if="loginState === 1" :label="t('common.email')" prop="email">
            <el-input
              v-model.trim="loginForm.email"
              class="elv-login-input"
              clearable
              :placeholder="t('placeholder.enterEmail')"
            >
              <!-- <template #suffix>
                <SvgIcon name="create_dialog" width="30" height="30" />
              </template> -->
            </el-input>
          </el-form-item>
          <div v-if="loginState === 1">
            <!-- 验证码 -->
            <el-form-item v-if="isPasswordLogin" :label="t('common.captcha')" prop="authCode">
              <el-input
                v-model="loginForm.authCode"
                class="elv-login-input"
                :placeholder="t('placeholder.enterNumber')"
                @keyup.enter="login"
              >
                <template #suffix>
                  <el-button
                    class="elv-send-button"
                    :disabled="verificationCodeOptions.disabled"
                    text
                    size="large"
                    :loading="verificationCodeOptions.isLoading"
                    @click="sendCaptcha"
                  >
                    {{ verificationCodeOptions.text }}
                  </el-button>
                </template>
              </el-input>
            </el-form-item>

            <!-- 密码 -->
            <el-form-item v-else :label="t('common.password')">
              <el-input
                v-model="loginForm.password"
                class="elv-login-input"
                :placeholder="t('placeholder.enterPassword')"
                type="password"
                show-password
                @keyup.enter="login"
              />
            </el-form-item>
          </div>

          <el-form-item v-if="loginState === 2" :label="t('input.nameLabel')" prop="name">
            <el-input v-model="loginForm.name" class="elv-login-input" :placeholder="t('placeholder.enterName')" />
          </el-form-item>
          <el-form-item v-if="loginState === 2" :label="t('input.passwordsLabel')" prop="password">
            <el-input
              v-model="loginForm.password"
              class="elv-login-input"
              type="password"
              show-password
              :placeholder="t('placeholder.enterPassword')"
            />
          </el-form-item>
          <!-- 协议勾选 -->
          <el-form-item>
            <el-row style="min-height: 68px">
              <el-form-item label-placement="left">
                <el-checkbox
                  v-model="loginForm.checkboxValue"
                  :checked="loginForm.checkboxValue"
                  class="elv-login-form-checkbox-content"
                >
                  {{ t('common.agreementText') }}&nbsp;
                  <!-- <router-link to="/pact/privacyAgreement" class="elv-login-form-checkbox-content-link"> </router-link> -->
                </el-checkbox>
                <span class="elv-login-form-checkbox-content-link" @click="onJumpService()">
                  {{ t('common.userService') }}
                </span>
              </el-form-item>
            </el-row>
          </el-form-item>

          <el-form-item>
            <div class="elv-login-button-box">
              <el-button
                v-if="loginState === 1"
                color="#1753EB"
                style="color: #ffffff"
                class="elv-login-button"
                :loading="loginForm.isLoading"
                @click="login"
              >
                {{ t('button.login') }}</el-button
              >
              <el-button
                v-if="loginState === 1 && isPasswordLogin"
                class="elv-login-button--plain"
                @click="changeLoginType"
              >
                {{ isPasswordLogin ? t('common.password') : t('common.captcha') }}
              </el-button>
              <el-button
                v-if="loginState === 1 && !isPasswordLogin"
                class="elv-login-button--plain"
                @click="changeLoginType"
              >
                {{ isPasswordLogin ? t('common.password') : t('common.captcha') }}
              </el-button>

              <el-button
                v-if="loginState === 2"
                color="#1753EB"
                style="color: #ffffff"
                class="elv-login-button"
                :loading="loginForm.isLoading"
                @click="onRegister"
              >
                {{ t('button.ok') }}</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts" setup>
import utils from '@/lib/utils'
import { $t } from '@/i18n/index'
import { useI18n } from 'vue-i18n'
import UserApi from '@/api/UserApi'
import { ElMessage } from 'element-plus'
import { useCookies } from 'vue3-cookies'
import type { FormInstance, FormRules } from 'element-plus'
import { useUserGlobalStore } from '@/stores/modules/global/user'

const { t } = useI18n()
const { cookies } = useCookies()
const userGlobalStore = useUserGlobalStore()
const { isShowLoginDialog } = storeToRefs(userGlobalStore)
const router = useRouter()

const isPasswordLogin = ref<boolean>(true)
const loginFormRef = ref<FormInstance>()
const loginState = ref<number>(1)
// 表单变量
interface VerificationCodeType {
  text: string
  duration: number
  disabled: boolean
  isLoading: boolean
  timer: any
}
const verificationCodeOptions = reactive<VerificationCodeType>({
  text: t('button.sendCaptchaText'),
  duration: 60,
  isLoading: false,
  disabled: false,
  timer: null
})

const loginForm = ref({
  email: '',
  authCode: '',
  password: '',
  name: '',

  checkboxValue: true,
  showIcon: true,
  isLoading: false
})

// 表单规则定义
const rules = reactive<FormRules>({
  email: [
    {
      required: true,
      message: t('message.pleaseInputEmail'),
      trigger: 'blur',
      validator(rule: any, value: string) {
        if (!value) {
          return new Error(`${t('message.pleaseInputEmail')}.`)
        }
        // eslint-disable-next-line no-useless-escape
        if (!/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,})+$/.test(value)) {
          return new Error(`${t('message.pleaseInputEmail')}.`)
        }
        return true
      }
    }
  ],
  authCode: [
    {
      required: true,
      message: `${t('message.PleaseInputCode')}`,
      validator(rule: any, value: string) {
        if (!value) {
          return new Error(`${t('message.captchaWarning')}.`)
        }
        return true
      },
      trigger: 'blur'
    }
  ],
  name: [
    {
      required: true,
      message: `${t('message.pleaseInputName')}`,
      validator(rule: any, value: string) {
        if (!value) {
          return new Error(`${t('message.pleaseInputName')}`)
        }
        return true
      },
      trigger: ['blur']
    }
  ],
  password: [
    {
      required: true,
      validator(rule: any, value: string) {
        if (!value) {
          return new Error(`${t('message.pleaseInputPassword')}`)
        }
        const reg =
          /^S*(?=\S{8,})(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]/

        if ((!reg.test(value) && loginState.value === 2) || value.length < 6) {
          return new Error(`${t('message.passwordError')}`)
        }

        return true
      },
      trigger: ['blur']
    }
  ]
})

// 改变验证码按钮方法
function timer() {
  verificationCodeOptions.disabled = true
  // 倒计时方法
  // 清除掉定时器
  // eslint-disable-next-line no-unused-expressions
  verificationCodeOptions.timer && clearInterval(verificationCodeOptions.timer)
  // 开启定时器
  verificationCodeOptions.timer = setInterval(() => {
    // eslint-disable-next-line no-plusplus
    const tmp = verificationCodeOptions.duration--
    verificationCodeOptions.text = `${tmp}${` ${t('common.sendCaptchaCountdown')}`}`

    if (tmp <= 0) {
      // 清除掉定时器
      clearInterval(verificationCodeOptions.timer)
      verificationCodeOptions.duration = 60
      verificationCodeOptions.text = `${t('button.sendCaptchaText')}`
      // 设置按钮可以单击
      verificationCodeOptions.disabled = false
    }
  }, 1000)
}

// 获取验证码方法
// eslint-disable-next-line no-unused-vars
const sendCaptcha = (e: MouseEvent) => {
  // 此处只校验 是否填写邮箱
  loginFormRef.value?.validateField('email', async (isValidate) => {
    if (isValidate) {
      try {
        verificationCodeOptions.isLoading = true
        await UserApi.sendCaptcha(loginForm.value.email)
        timer()
        verificationCodeOptions.isLoading = false
      } catch (errors: any) {
        ElMessage(utils.formatLanguageContent(errors.message))
      }
    }
  })
}

// 登录
const login = async () => {
  loginFormRef.value?.validate(async (isValidate) => {
    if (!loginForm.value.checkboxValue) {
      return false
    }
    loginForm.value.isLoading = true
    if (isValidate) {
      try {
        const { data } = await UserApi.userLogin(
          loginForm.value.email,
          Number(loginForm.value.authCode),
          loginForm.value.password
        )
        // 登录成功
        cookies.set('elv-app-token', data.token, '7d', '/', import.meta.env.VITE_APP_COOKIES_DOMAIN, true)
        await userGlobalStore.userInit()
        // 跳转页面
        // router.push('/')
        userGlobalStore.closeLogin()
      } catch (error: any) {
        // 未设置密码
        if (error.code === 10011) {
          loginState.value = 2
        } else {
          ElMessage.error(utils.formatLanguageContent(error.message))
        }
      }
    }
    loginForm.value.isLoading = false
    return true
  })
}
// 注册
const onRegister = async () => {
  if (!loginForm.value.checkboxValue) {
    return false
  }
  loginFormRef.value?.validate(async (isValidate) => {
    if (isValidate) {
      try {
        loginForm.value.isLoading = true
        const params = {
          email: loginForm.value.email,
          name: loginForm.value.name,
          password: loginForm.value.password,
          code: Number(loginForm.value.authCode)
        }
        const { data } = await UserApi.userRegister(params)
        // 注册成功
        cookies.set('elv-app-token', data.token, '7d', '/', import.meta.env.VITE_APP_COOKIES_DOMAIN, true)
        await userGlobalStore.userInit()
        // 跳转页面
        // router.push('/')
        userGlobalStore.closeLogin()
      } catch (error: any) {
        loginForm.value.isLoading = false
        ElMessage.error(utils.formatLanguageContent(error.message))
      }
    }
  })
  return true
}

// 改变登录类型
const changeLoginType = () => {
  loginForm.value.authCode = ''
  loginForm.value.password = ''
  isPasswordLogin.value = !isPasswordLogin.value
}

// 关闭登录弹窗
const onCloseDialog = () => {
  userGlobalStore.closeLogin()
}

// 跳转隐私协议
const onJumpService = () => {
  router.push('/pact/privacyAgreement')
  setTimeout(() => {
    userGlobalStore.closeLogin()
  }, 500)
}
</script>

<style scoped lang="scss">
.elv-login-dialog-main {
  margin: 0;
  padding: 0;
  margin-left: 80px;

  .elv-login-dialog__svg {
    height: 36px;
    width: 100%;
  }

  .elv-login-dialog__dsc {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #999ba0;
    margin-bottom: 48px;
    margin-top: 24px;
  }
}

.elv-login-form-checkbox-content {
  color: #999ba0 !important;
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: 14px;
  height: 14px;
  line-height: 14px;
}

.elv-login-form-checkbox-content-link {
  color: #1753eb;
  text-decoration: none;
  border-bottom: 1px solid #1753eb;
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: 14px;
  height: 14px;
  line-height: 14px;
  padding-bottom: 1px;
  //   position: relative;
  //   top: 2px;
  cursor: pointer;
}

.elv-login-button-box {
  padding-bottom: 60px;
  display: flex;
  flex-wrap: wrap;

  .elv-login-button {
    width: 320px;
    height: 44px;
    margin-top: 10px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 14px;
    border-radius: 28px;
  }

  .elv-login-button--plain {
    width: 320px;
    height: 44px;
    margin-top: 10px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 14px;
    border-radius: 28px;
    border: none;
    margin-left: 0;

    &:hover {
      color: #1343bf;
    }
  }
}

.elv-login-input {
  height: 44px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 14px;
  caret-color: #2a56e2;
}

.elv-send-button {
  // width: 36px;
  height: 14px;
  color: #0e1420;

  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 14px;

  &:hover {
    color: #2d62eb;
    background-color: #ffffff !important;
  }
}
</style>
<style lang="scss">
.elv-login-dialog {
  .el-dialog__header {
    height: 0;
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
    padding-top: 80px;
  }

  .el-form-item__label {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #0e1420;

    &::before {
      // 隐藏掉必填的小红星
      display: none;
    }
  }

  .el-input__inner {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #1e2024;
  }
  /*修改提示字的颜色*/
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #aaafb6;
  }

  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #aaafb6;
  }

  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #aaafb6;
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #aaafb6;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    --el-checkbox-checked-bg-color: #1753eb;
    --el-checkbox-checked-input-border-color: #1753eb;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #999ba0;
  }

  .el-input__wrapper:hover {
    box-shadow: 0 0 0 1px #2a56e2 inset;
  }

  .el-input__wrapper.is-focus {
    box-shadow: 0 0 0 1px #2a56e2 inset;
  }

  .el-form-item.is-error .el-input__wrapper {
    box-shadow: 0 0 0 1px #e17570 inset;
  }

  .el-form-item__error {
    color: #e17570;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    margin-top: 4px;
  }
}
</style>
