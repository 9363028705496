<template>
  <div
    v-if="isFixedMenu && ((navItem.soon && nameLength > 5) || nameLength > 8 || navItem.soon)"
    class="elv-layout-main-menu-main-item"
  >
    <el-popover
      :show-arrow="false"
      placement="top-start"
      :width="200"
      :enterable="false"
      :show-after="500"
      trigger="hover"
    >
      <div class="elv-layout-main-menu-main-nav-box-main__tip">
        <div
          v-if="(navItem.soon && nameLength > 5) || (!navItem.soon && nameLength > 8)"
          class="elv-layout-main-menu-main-nav-box-main__tip--name"
        >
          {{ navItem.name }}
        </div>
        <div v-if="navItem.soon" class="elv-layout-main-menu-main-nav-box-main__tip--soon">
          {{ t('common.willBeComingSoon') }}
        </div>
      </div>

      <template #reference>
        <div
          class="elv-layout-main-menu-main-nav-box-main"
          :class="{ 'elv-layout-main-menu-main-box-main__choose': props.isChoosed }"
          @click="onNavChange(navItem)"
          @mouseenter="onMouseEnter"
          @mouseleave="onMouseLeave"
        >
          <div class="elv-layout-main-menu-main-nav-box__name-soon">
            <span
              class="elv-layout-main-menu-main-nav-box__name"
              :class="{ 'elv-layout-main-menu-main-nav-box__name--nosoon': !navItem.soon }"
              >{{ navItem.name }}</span
            >
            <span v-if="navItem.soon" class="elv-layout-main-menu-main-nav-box__soon">SOON</span>
          </div>

          <div v-if="navItem.line" class="elv-layout-main-menu-main-nav-box__line--box">
            <div class="elv-layout-main-menu-main-nav-box__line"></div>
          </div>
        </div>
      </template>
    </el-popover>
  </div>
  <div v-else class="elv-layout-main-menu-main-item">
    <div
      class="elv-layout-main-menu-main-nav-box-main"
      :class="{ 'elv-layout-main-menu-main-box-main__choose': props.isChoosed }"
      @click="onNavChange(navItem)"
    >
      <div class="elv-layout-main-menu-main-nav-box__name-soon" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
        <span
          class="elv-layout-main-menu-main-nav-box__name"
          :class="{ 'elv-layout-main-menu-main-nav-box__name--nosoon': !navItem.soon }"
          >{{ navItem.name }}</span
        >
        <span v-if="navItem.soon" class="elv-layout-main-menu-main-nav-box__soon">SOON</span>
      </div>

      <div v-if="navItem.line" class="elv-layout-main-menu-main-nav-box__line--box">
        <div class="elv-layout-main-menu-main-nav-box__line"></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import { useUserGlobalStore } from '@/stores/modules/global/user'

const { t } = useI18n()
const userGlobalStore = useUserGlobalStore()
const { isLogin } = storeToRefs(userGlobalStore)

const menuGlobalStore = useMenuGlobalStore()
const { isFixedMenu } = storeToRefs(menuGlobalStore)

const props = defineProps({
  navItem: {
    type: Object,
    default: () => {}
  },
  isChoosed: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['onNavChange'])

const isVisiblePopover = ref(false)
const nameLength = ref(5)
const chooseId = ref('fundraising-deals')

// 改变nav
const onNavChange = (val: any) => {
  if (val.requiresAuth && !isLogin.value) {
    userGlobalStore.openLogin()
  } else if (!val.soon) {
    chooseId.value = val.name
    emit('onNavChange', val)
  }
}

// 鼠标移入
const onMouseEnter = () => {
  nameLength.value = utils.validatStrLength(props.navItem.name)
  if (isFixedMenu.value) {
    if (props.navItem.soon || nameLength.value > 8 || (props.navItem.soon && nameLength.value > 5)) {
      isVisiblePopover.value = true
    }
  } else {
    isVisiblePopover.value = false
  }
}

// 鼠标移出
const onMouseLeave = () => {
  isVisiblePopover.value = false
}
</script>
<style lang="scss" scoped>
.elv-layout-main-menu-main-item {
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  .elv-layout-main-menu-main-nav-box-main {
    display: flex;
    flex-wrap: wrap;
    height: 26px;
    padding-left: 32px;
    padding-right: 20px;

    .elv-layout-main-menu-main-nav-box__name-soon {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      padding: 0 8px;
      height: 24px;

      .elv-layout-main-menu-main-nav-box__name {
        align-items: center;
        height: 24px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: $elv-color-1E2024;
        box-sizing: border-box;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 72px;
        cursor: pointer;
      }

      .elv-layout-main-menu-main-nav-box__name--nosoon {
        max-width: 105px;
      }

      .elv-layout-main-menu-main-nav-box__soon {
        height: 12px;
        margin-left: 4px;
        line-height: 12px;
        padding: 2px 4px;
        border: 1.5px solid $elv-color-B3BFCE;
        border-radius: 2px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 700;
        font-size: 12px;
        zoom: 0.9;
        -moz-transform: scale(0.9);
        -moz-transform-origin: center;
        display: flex;
        align-items: center;
        color: $elv-color-B3BFCE;
      }
    }

    .elv-layout-main-menu-main-nav-box__line--box {
      height: 1px;
      width: 100%;

      .elv-layout-main-menu-main-nav-box__line {
        height: 1px;
        width: 121px;
        background: $elv-theme-icon-color;
      }
    }

    &:hover {
      .elv-layout-main-menu-main-nav-box__name-soon {
        background: $elv-color-E9EFFF;
        border-radius: 20px;

        .elv-layout-main-menu-main-nav-box__name {
          color: $elv-color-0E1420;
        }

        .elv-layout-main-menu-main-nav-box__soon {
          background: $elv-color-E9EFFF;
          color: rgb(183, 203, 253);
          border: 1.5px solid rgb(183, 203, 253);
          zoom: 0.9;
          -moz-transform: scale(0.9);
          -moz-transform-origin: center;
        }
      }
    }
  }

  .elv-layout-main-menu-main-box-main__choose {
    .elv-layout-main-menu-main-nav-box__name-soon {
      background: $elv-color-E9EFFF;
      border-radius: 20px;

      .elv-layout-main-menu-main-nav-box__name {
        color: $elv-color-1343BF !important;
        font-weight: 600;
      }
    }
  }
}
</style>

<style lang="scss">
.elv-layout-main-menu-main-nav-box-main__tip {
  padding: 16px;

  .elv-layout-main-menu-main-nav-box-main__tip--name {
    font-family: 'Plus Jakarta Sans';
    font-weight: 800;
    font-size: 11px;
    line-height: 140%;
    color: $elv-theme-base-text-color;
  }

  .elv-layout-main-menu-main-nav-box-main__tip--soon {
    font-family: 'Plus Jakarta Sans';
    margin-top: 7px;
    font-weight: 700;
    font-size: 11px;
    line-height: 140%;
    color: $elv-theme-base-text-color;
  }
}

.elv-layout-main-menu-main-box {
  .el-collapse {
    border-top: 0px;
  }

  .el-collapse-item__header {
    border: none;
    background: $elv-color-F9FAFB;
  }

  .el-collapse-item__wrap {
    border: none;
    background: $elv-color-F9FAFB;
  }

  .el-collapse-item__content {
    padding-bottom: 0;
  }

  .el-collapse-item__arrow {
    margin: 10px 16px 0 0;
  }
}
</style>
